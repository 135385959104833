import styled from "styled-components";


const GetAppSectionWrap = styled.section`
padding: 1rem 10rem 40rem 10rem;
background-color: white;
background-repeat: no-repeat;
background-size: contain;
background-position: center center;
position: relative;

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.alamat
{
	text-align:left;
	line-height:26px;
}
.kolom
{
	margin-left:auto;
	margin-right:auto;
	display:block;
}

.hubungi-keterangan
{
	margin-bottom:2rem;
	font-family: 'Roboto',sans-serif;
}
.hubungi
{
	font-family: 'Roboto',sans-serif;
	font-weight:bold;
	font-size:30px;
	margin-bottom:1rem;
}
.formDaftar
{
	
	border: 1px solid lightgray;
	padding:20px;
	padding-top:40px;
	height:130%;
}

.subscribeform-padding
{
	text-align:left;
	input
	{

		margin-bottom:15px;
		width:100%;
		color:black;
	}
	textarea
	{
		margin-bottom:15px;
		width:100%;
		color:black;
		border: 1px solid lightgray;
		resize:none;

	}

	.isipesan
		{
			height:200px;
		}

	select
	{
		font-weight:600;
		color:black;
		margin-bottom:15px;
		width:100%;
		border:1px solid lightgray;
	}
}

.padding-keterangan
{
	padding-left:4px;
}

.buttonWidth{
	width:100%;
}

.subscribe-form {
	margin-left:-30px
	position: absolute;
	display: block;
	input {
		background: transparent;
		flex-basis: 100%;
		padding: 10px;	
		border: 1px solid lightgray;
	}

}

.section__orang {
	position: absolute;
	&.four {
		width: 20%;
		top: -3%;
		right: 12.333%;
	}
}

.dropDown {
	width: 78%;
	background: transparent;
	flex-basis: 100%;
	padding: 10px;
	border: 3px solid #FFFFFF;
}

.radiusInput {
	border: 3px solid #ffffff;
	box-sizing: border-box;
	border-radius: 10px;
}

.centered {
	text-align: center;
	height: 200px;
}

.wrap {
	border-radius: 30px
	background-size: cover;
	height: calc(100% + 340px);
}

.colorText {
	color: white;
}

.segment {
	height: 200px;
	width: 50%;

	top: 50px;
	position: relative;
	margin: 0 auto;
}
.banner-btn.one {
	border-radius:15px;
	display:block;
	margin-left:auto;
	margin-right:auto;
	background: #84BD00;
	width: 40%;
	color: white;
	font-family: 'Roboto',sans-serif;
	font-weight: 400;
	&:hover {
		background: #eee6fa;
		color: #8a57de;
	}
}
.banner-btn.two {
	background: #fff309;
	width: 100%;
	border-radius: 50px;
	color: #6bb23f;
	&:hover {
		background: #eee6fa;
		color: #8a57de;
	}
}
.section__particle {
	position: absolute;
	&.one {
		width: 70px;
		left: 7%;
		top: 28px;
	}

	&.two {
		width: 60px;
		bottom: -10px;
		left: 5%;
	}
	&.three {
		width: 10%;
		right: 45%;
		top: 20px;
	}
	&.four {
		width: 95px;
		bottom: 80px;
		right: 25%;
	}
}
.getapp-block {
	h3 {
		font-family: fredoka one;
		font-size: 40px;
		font-weight: 400;
		line-height: 50px;
		margin-bottom: 10px;
		color: #91CA50;
	}
	h4
	{
		font-family: fredoka one;
		font-size: 20px;
		font-weight: 400;
		line-height: 50px;
		margin-bottom: 10px;
		color: #91CA50;
	}
	p {
		font-family: Roboto;
		font-weight: 400;
		line-height: 35px;
		font-size: 16px;
	}
}

.btn-block {
	display: flex;
	justify-content: space-between;
}
.getapp-btn {
	display: flex;
	align-items: center;
	height: 90px;
	min-width: 260px;
	border-radius: 5px;
	padding: 0 12px;

	border: 1px solid rgba(122, 132, 142, .5);
	-webkit-transition: 450ms all;
	transition: 450ms all;
	p {
		margin-bottom: 0;
		color: ${(props) => props.theme.primaryColor};
	}
	span {
		display: block;
		font-size: 20px;
		font-weight: 500;
		color: ${(props) => props.theme.textColor};
	}
	svg {
		font-size: 35px;
		color: ${(props) => props.theme.textColor};
	}
	&:hover {
		color: ${(props) => props.theme.primaryColor};
		background: ${(props) => props.theme.white};
		border-color: transparent;
		span,
		svg {
			color: ${(props) => props.theme.primaryColor};
		}
	}
}
.apl-btn {
	p {
		color: ${(props) => props.theme.secondaryColor};
	}
	svg {
		font-size: 45px;
		color: ${(props) => props.theme.textColor};
	}
	&:hover {
		color: ${(props) => props.theme.secondaryColor};
		span,
		svg {
			color: ${(props) => props.theme.secondaryColor};
		}
	}
}
.getapp-thumb {
	margin: 0 auto;
	text-align: center;
	img {
		margin-top: -80px;
		box-shadow: 0 0 40px ${(props) => props.theme.shadowColor};
	}
}
@media only screen and (max-width: 1280px) {
	background-size: cover;
	.dropDown{
		width:95%;
	}
	.section__orang {
		position: absolute;
		&.four {
			width: 20%;
			top: 100%;
			right: 0.333%;
		}
	}
	.getapp-btn {
		min-width: auto;
		width: 48%;
		p {
			font-size: 12px;
		}
		span {
			font-size: 17px;
		}
	}
	.section__particle {
		&.four {
			display: none;
		}
	}

	.kolom
	{
		margin-right:auto;
		margin-left:auto;
	}

	.subscribeform-padding
	{
		input
		{
			width:100%;
		}

		select
		{
			width:100%;
		}
	}
}
@media only screen and (max-width: 912px) {
	padding: 80px 0 580px 0;
	background-size: cover;

	.kolom
	{
		margin-right:auto;
		margin-left:auto;
	}
	.dropDown{
		width:95%;
	}
	.wrap{
		margin-bottom: 50px;
	}
	.getapp-block {
		h3 {
			font-size: 30px;
			margin-bottom: 8px;
		}
	}
	.btn-block {
		display: block;
	}
	.getapp-btn {
		min-width: auto;
		width: 100%;
		p {
			font-size: 16px;
		}
		span {
			font-size: 19px;
		}
	}
	.getapp-btn + .getapp-btn {
		margin-top: 30px;
	}

	.subscribeform-padding
	{
		input
		{
			width:100%;
		}

		select
		{
			width:100%;
		}

	
	}

	
@media only screen and (max-width: 760px) {
	padding: 80px 0 550px 0;
	.dropDown{
		width:95%;
	}
	.formDaftar
	{
		width:100%;
	}


	.section__particle {
		&.two {
			bottom: auto;
			top: 0;
			right: auto;
			left: 10%;
		}
		&.one,
		&.three {
			display: none;
		}
	}
	.getapp-btn {
		justify-content: center;
	}
	.getapp-btn {
		p {
			margin: 0 26px 0px 26px;
		}
	}


}

@media only screen and (max-width: 568px) {
	padding: 50px 0 500px 0;
	text-align: center;
	.dropDown{
		width:95%;
	}
	.getapp-block {
		.btn-block {
			display: flex;
		}
		.getapp-btn {
			width: 260px;
			span {
				font-size: 17px;
			}
			p {
				font-size: 13px;
				margin: 0 15px 0px 15px;
			}
		}
		.getapp-btn + .getapp-btn {
			margin-top: 0;
		}
	}
	.getapp-thumb {
		display: none;
	}

	.formDaftar
	{
		width:100%;
	}

	.wrap{
		margin-top:20px;
		margin-bottom: 50px;
	}

	.subscribeform-padding
	{
		input
		{
			width:100%;
		}

		.isipesan
			{
				height:200px;
			}

		select
		{
			font-weight:600;
			color:black;
			margin-bottom:10px;
			width:100%;
		}
	}
	.centered {
		text-align: center;
		height: auto;
	}

	.alamat
	{
		text-align:center;
		line-height:26px;
	}
}
@media only screen and (max-width: 480px) {
	.dropDown{
		width:95%;
	}
	.getapp-block {
		.btn-block {
			display: block;
		}
		.getapp-btn {
			width: 100%;
			span {
				font-size: 23px;
			}
			p {
				font-size: 16px;
				margin: 0 15px 0px 15px;
			}
		}
		.getapp-btn + .getapp-btn {
			margin-top: 30px;
		}
	}
}
`;

// CSS DEFAULT APPION

// .getapp-block {
// 	h3 {
// 		font-size: 40px;
// 		font-weight: 500;
// 		line-height: 50px;
// 		margin-bottom: 40px;
// 	}
// 	p {
// 		font-weight: 300;
// 		margin: 0 auto 60px auto;
// 	}
// }

export default GetAppSectionWrap;
