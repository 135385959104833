import React from "react";

import { Container, Row, Col } from "../../../reusecore/LayoutContactus";
import {} from "../../../reusecore/LayoutContactus";
import Heading from "../../../reusecore/Heading";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

import GetAppSectionWrap from "./getAppSection.style";

const GetApp = () => {
  return (
    <GetAppSectionWrap>
      <Container>
        <Row Vcenter={true}>
          <Col xs={12} sm={8} className="kolom">
            <div className="ui centered aligned grid">
              <Heading content={<FormattedMessage id="contactUsFormTitle" />} />
              <div className="wrap">
                <div className="formDaftar">
                  {/* <div className="hubungi">HUBUNGI KAMI</div> */}
                  {/* <div className="hubungi-keterangan">
                    Tolong lengkapi formulir di bawah ini lalu klik tombol Kirim
                    dan anda akan terhubung dengan kami
                  </div> */}

                  <br />
                  <form
                    className="subscribe-form subscribeform-padding"
                    action="https://formspree.io/f/mleoopap"
                    method="POST"
                  >
                    <strong>
                      <FormattedMessage id="formName" />
                    </strong>
                    <span style={{ color: "red" }}>*</span>
                    <input type="text" name="name" required/>
                    <strong>
                      <FormattedMessage id="formEmail" />
                    </strong>
                    <span style={{ color: "red" }}>*</span>
                    <input type="text" name="email" required/>
                    {/*             
                    <input
                      type="text"
                      placeholder="Judul pesan"
                      name="titleMessage"
                    /> */}
                    <strong>
                      <FormattedMessage id="formWA" />
                    </strong>
                    <span style={{ color: "red" }}>*</span>
                    <input type="number" name="noWhatsapp" required/>
                    <strong>
                      <FormattedMessage id="formSubject" />
                    </strong>
                    <span style={{ color: "red" }}>*</span>
                    <input type="text" name="subjek" required/>
                    {/* <select name="Posisi" className="dropDown" required>
                      <option value="1">Pengguna</option>
                      <option value="2">Principal</option>
                    </select> */}
                    <strong>
                      <FormattedMessage id="formMessage" />
                    </strong>
                    <span style={{ color: "red" }}>*</span>
                    <textarea
                      style={{ height: "150px" }}
                      type="text"
                      name="Message"
                      resize="none"
                      required
                    />

                    <input
                      type="submit"
                      placeholder="No Telepon"
                      className="banner-btn one buttonWidth"
                      value="Submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={4} >
            <div className="ui centered aligned grid">
            <Heading content={<FormattedMessage id="OurAddress" />} />
              <div className="wrap">
                <div className="alamat">
                  <hr/>
                  Kalbe Business Innovation Center Lt. 4
                  <br/>
                  Jl. Pulogadung No. 23 Kav. II G5
                  <br/>
                  Kawasan Industri Pulogadung
                  <br/>
                  Jakarta Timur 13930
                  <br/>
                  Phone	: 021-5086 7668
                  <br/>
                  Fax		: 021-5086 7669
                </div>
              </div>
            </div>
          </Col>
          {/* <img className="section__orang four" src={orang} /> */}
        </Row>
      </Container>
    </GetAppSectionWrap>
  );
};

export default injectIntl(GetApp);
